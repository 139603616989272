<template>
  <div class="home">
    <!-- 轮播图 -->
    <div class="slideshow_Box">
      <swiper
        :options="swiperOption"
        class="van-swipe"
        v-if="showList.length > 0"
      >
        <swiper-slide v-for="item in showList" :key="item.bannerId">
          <div class="slideshow">
            <div class="slideshowText">
              <div class="slideshowText_title">{{ item.bannerName }}</div>
              <div class="slideshowText_text">{{ item.bannerDescribe }}</div>
            </div>
            <img :src="item.fileUrl" alt="" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <img class="slogan" src="@/assets/home/slogan.png" alt="" />
    </div>
    <!-- 产品 -->
    <div class="product">
      <div class="product_top">
        <div style="min-width: 1900px">
          <div class="title">丰富、专业、智能的产品及服务</div>
          <span @click="$router.push('/product')">查看中飞科技全部产品></span>
        </div>

        <ul class="product_list">
          <li
            v-for="item in productClassificationList"
            :key="item.classificationId"
            @mouseenter="changeProduct(item)"
          >
            <img :src="item.image" alt="" />
            <div class="classificationName">{{ item.classificationName }}</div>
          </li>
        </ul>
      </div>
      <div class="product_bottom">
        <ul class="product_details_list">
          <li v-for="item in productList" :key="item.productId">
            <strong>{{ item.productName }}</strong>
            <p>
              {{ item.synopsis }}
            </p>
            <button @click="$router.push(`/productDetails/${item.productId}`)">
              立即查看
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <div class="solution_top">
        <div class="title">全面、高效、一站式解决方案</div>
        <swiper
          :options="sideSwiperOption"
          class="swiper_solution"
          v-if="slideshowList.length > 0"
        >
          <swiper-slide
            v-for="item in slideshowList"
            :key="item.bannerId"
            class="swiper_slide_solution"
          >
            <img :src="item.fileUrl" alt="" />
          </swiper-slide>
          <div class="swiper-pagination-solution" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- 数据展示 -->
    <div class="showData">
      <img src="@/assets/home/showData.png" alt="" />
    </div>
    <!-- 新闻动态 -->
    <div class="news_Trends">
      <div class="title">新闻动态</div>
      <div class="news_info">
        <div class="news_img">
          <img src="@/assets/home/news.png" alt="" />
        </div>
        <div class="news_show">
          <ul>
            <li
              v-for="item in newsList"
              :key="item.newsId"
              @click="$router.push(`/newsDetails/${item.newsId}`)"
            >
              <span>{{ item.title }} </span>
              <span>{{ item.remark }}</span>
            </li>
            <!-- <li>
              <span
                >热烈祝贺“泰山杯”全国医学影像技术专业青年教师教学比赛、大学生（本科）实践技能大赛暨学术会议胜利召开
              </span>
              <span>山东中飞科技独家全程技术支持</span>
            </li>
            <li>
              <span
                >热烈祝贺“泰山杯”全国医学影像技术专业青年教师教学比赛、大学生（本科）实践技能大赛暨学术会议胜利召开
              </span>
              <span>山东中飞科技独家全程技术支持</span>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homepageInfo } from "@/api/web.js";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@/style/swiper.css";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        speed: 500, // 切换速度
        autoplay: {
          delay: 3000, // 自动切换的时间间隔
          disableOnInteraction: false, //  用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        // pauseOnMouseEnter: true, // 鼠标置于swiper时暂停自动切换，鼠标离开时恢复自动切换
        loop: true, // 无限循环
        simulateTouch: false, //在pc端禁止滑动
        effect: "fade", // 设置Slide的切换效果
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器的指示点分页器会控制Swiper切换
        },
      },
      sideSwiperOption: {
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        speed: 1000, // 切换速度
        autoplay: {
          delay: 5000, // 自动切换的时间间隔
          disableOnInteraction: false, //  用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        loop: true,
        direction: "vertical", // Swiper的滑动方向
        simulateTouch: false, //在pc端禁止滑动
        pagination: {
          el: ".swiper-pagination-solution",
          type: "bullets",
          clickable: true, // 点击分页器的指示点分页器会控制Swiper切换
        },
      },
      showList: [],
      slideshowList: [],
      productClassificationList: [], // 产品服务
      productList: [], // 产品分类对应下面的产品
      newsList: [], // 新闻
    };
  },
  created() {
    this.getHomeInfo();
  },
  methods: {
    async getHomeInfo() {
      const { data } = await homepageInfo();
      this.showList = data.banner[0];
      if (data.banner[1]) {
        this.slideshowList = data.banner[1];
      }
      this.productClassificationList = data.productClassification;
      this.productList = data.productClassification[0].productList;
      this.newsList = data.news;
    },
    changeProduct(item) {
      this.productList = item.productList;
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  max-width: 1920px;
  margin: 0 auto;
  // 轮播图部分
  .slideshow_Box {
    // width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .slideshow {
      height: 668px;
      width: 1920px;
      margin: 0 auto;
      .slideshowText {
        position: absolute;
        top: 120px;
        left: 10%;
        .slideshowText_title {
          font-size: 32px;
        }
        .slideshowText_text {
          font-size: 20px;
          max-width: 650px;
        }
      }
      img {
        width: 100%;
      }
    }
    .slogan {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
  }
  // 产品
  .product {
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
    .product_top {
      padding-top: 51px;
      width: 100%;
      background-color: #fff;
      text-align: center;
      .title {
        min-width: 100%;
        margin: 0 auto;
        font-size: 33px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #181818;
      }
      span {
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #27499d;
        cursor: pointer;
      }
      .product_list {
        display: flex;
        justify-content: space-between;
        padding: 0 232px;
        padding-left: 230px;
        margin-top: 65px;
        cursor: pointer;
        min-width: 1400px;
        li {
          flex: 1;
          position: relative;
          &:hover::after {
            content: "";
            position: absolute;
            left: -20%;
            bottom: -30px;
            width: 341px;
            height: 20px;
            background: url("../../assets/home/arrow.png") no-repeat;
          }
          img {
            width: 140px;
            height: 128px;
          }
          .classificationName {
            margin-top: 12px;
            font-size: 21px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #181818;
          }
        }
      }
    }
    .product_bottom {
      margin-top: 48px;
      width: 100%;
      min-width: 1900px;
      padding-left: 190px;
      padding-right: 160px;
      padding-top: 60px;
      padding-bottom: 45px;
      box-sizing: border-box;
      background-color: #eaeaea;
      .product_details_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 20%;
          margin-bottom: 60px;
          strong {
            display: inline-block;
            margin-bottom: 10px;
            font-size: 21px;
            text-align: center;
            font-weight: bold;
            color: #181818;
          }
          p {
            width: 200px;
            height: 125px;
            max-height: 300px;
            font-size: 16px;
            font-weight: 400;
            color: #323232;
            line-height: 25px;
          }
          button {
            width: 200px;
            height: 58px;
            margin-top: 37px;
            background: #f39800;
            border: none;
            color: #fff;
            font-size: 18px;
            outline: none;
            cursor: pointer;
          }
        }
      }
    }
  }
  // 解决方案
  .solution {
    max-height: 550px;
    box-sizing: border-box;
    overflow: hidden;
    min-width: 1900px;
    .solution_top {
      padding: 50px 232px 0 232px;
      // padding-top: 50px;
      .title {
        margin: 0 auto;
        margin-bottom: 45px;
        width: 429px;
        font-size: 33px;
        color: #181818;
      }
    }
  }
  // 数据展示
  .showData {
    width: 100%;
    overflow: hidden;
  }
  // 新闻动态
  .news_Trends {
    width: 100%;
    margin-top: 50px;
    min-width: 1900px;
    padding: 0 235px;
    margin-bottom: 20px;
    box-sizing: border-box;
    .title {
      width: 132px;
      margin: 0 auto;
      margin-bottom: 30px;
      font-size: 33px;
      color: #181818;
    }
    .news_info {
      display: flex;
      .news_show {
        ul {
          li {
            padding: 32px 67px 37px 54px;
            height: 161px;
            width: 858px;
            box-sizing: border-box;
            background: #ffffff;
            border: 1px solid #ebebeb;
            &:nth-of-type(2) {
              border-top: none;
              border-bottom: none;
            }
            &:hover {
              transform: scaleY(1.01);
              transition: all 0.5s;
              box-shadow: 2px 5px 10px #b6bdcf;
            }
            span {
              display: block;
              &:nth-of-type(1) {
                font-size: 21px;
                font-weight: 400;
                line-height: 25px;
                color: #323232;
              }
              &:nth-of-type(2) {
                width: 240px;
                font-size: 16px;
                line-height: 40px;
                color: #284a9d;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

::v-deep {
  .van-swipe {
    width: 1920px;
    position: relative;
    overflow: hidden !important;
    .swiper-pagination-bullet {
      display: inline-block;
      width: 60px;
      height: 3px;
      background-color: #c8c8c8;
      margin-right: 15px;
      border-radius: 0;
      opacity: 1;
      transition: all 0.3s;
    }
    .swiper-pagination-bullet-active {
      background-color: #f29904;
    }
    .swiper-slide {
      width: 100% !important;
      margin: 0 auto;
    }
    .swiper-pagination {
      position: absolute;
      bottom: 350px;
      left: -28%;
    }
  }

  //  解决方案轮播
  .swiper_solution {
    position: relative;
    overflow: hidden !important;
    max-height: 520px;
    padding-top: 10px;
    text-align: center;
    img {
      object-fit: cover;
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #eaeaea;
      transition: all 0.3s;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      height: 46px;
      border-radius: 30px;
      background-color: #f29904;
    }
  }
  .swiper-pagination-solution {
    position: absolute;
    bottom: 20%;
    right: 5%;
    z-index: 999;
  }
}
</style>
